.card {
  z-index: 0;

  height: 9rem;
  margin-bottom: 0.5em;
  padding: 0;
}

.cardInner {
  padding: 6%;
}

.col {
  margin: 0;
  padding: 0;
}

.library {
  margin-top: 5%;
  padding: 0 2% 2% 2%;
}

.library h3 {
  margin-bottom: 2%;
  text-align: left;
}

.library * {
  align-content: center;
  justify-content: center;
}

.card-subtitle {
  font-size: 0.75em;
  color: rgba(1, 1, 1, 0.5);
}

.sr-only {
  /* margin-left: 70px; */
  text-align: center;
  /* position:fixed;top:50%;left:50% */
}
