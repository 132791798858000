.floatingWindow {
  position: fixed;

  height: 70vh;
  width: 90vw;
  background-color: white;
  border-radius: 1em;
  align-content: center;
  text-align: center;
  box-shadow: 0px 0px 100rem 100rem rgba(0, 0, 0, 0.4);
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
  padding: 0 2%;

  transform: translate(-50%, -50%);

  transition: width 0.3s, height 0.3s;
}

.floatingWindow .btn {
  margin: 10px;
}
