.statusWindow {
  position: fixed;
  min-height: 27vw;
  min-width: 45vw;
  max-height: 60vh;
  max-width: 60vw;
  background-color: white;
  border-radius: 1em;
  align-content: center;
  text-align: left;
  box-shadow: 0 0 1000em 1000em rgba(0, 0, 0, 0.4);
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.statusWindow .btn {
  margin: 10px;
}
