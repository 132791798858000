.blurb {
  min-width: 80%;
  width: fit-content;
  width: -moz-fit-content;
  height: fit-content;
  min-height: 10%;
  padding: 5%;
  background-color: gainsboro;
  margin-left: 50%;
  transform: translate(-50%, 0);
  border-radius: 1rem;
  box-shadow: 0 0 0.2em 0.2em rgba(0, 0, 0, 0.3) inset;
}

.blurb * {
  font-family: Montserrat;
  font-style: italic;
  font-size: 3vw;
}

@media screen and (max-width: 768px) {
  .blurb * {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 990px) {
  .blurb * {
    font-size: 2rem;
  }
}
