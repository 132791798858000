.iconLoading {
  margin-left: -17px;
  color: white;
  margin-top: -57px;
  position: relative;
  border-radius: 3px;
  width: fit-content;
  width: -moz-fit-content;
  width: max-content;
  max-width: 3rem;
  background-color: #ff3333;
  padding: 0 3px 0 3px;
}

.iconDisplay {
  color: white;
  position: relative;
  border-radius: 3px;
  width: fit-content;
  width: -moz-fit-content;
  max-width: 3rem;
  background-color: #29a329;
}
