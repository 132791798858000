#copier {
  position: relative;
  width: 2em;
  height: 2em;
  margin: 0.5em;
  left: 50%;
  transform: translate(-75%, 0);
}

#copier #copy-button {
  width: inherit;
  height: inherit;
  align-content: center;
  border-radius: 50%;
}

#copier #copy-button:hover {
  cursor: pointer;
}

#copier #copy-button img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  transform: translate(0, 50%);
}
