#fileEntry h2 {
  font-size: 5vw;
}

#fileEntry h4 {
  font-size: 4vw;
}

@media screen and (max-width: 425px) {
  #fileEntry h2 {
    font-size: 1.5rem;
  }

  #fileEntry h4 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  #fileEntry h2 {
    font-size: 2rem;
  }

  #fileEntry h4 {
    font-size: 1.5rem;
  }
}
